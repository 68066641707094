<template>
    <main class="main">
        <nav class="breadcrumb-nav border-0 mb-0">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">{{ $t('customer_service') }}</li>
                    <li class="breadcrumb-item active">{{ $t('privacy_policy') }}</li>
                </ol>
            </div>
        </nav>

        <div class="container">
            <div class="privacy-policy mb-3">
                <h5 class="text-primary"><strong>{{ $t('privacy_policy') }}</strong></h5>
                <p class="mb-1"><strong>{{ $t('privacy_policy') }}</strong></p>
                <p class="mb-2"><strong>Over ons {{ $t('privacy_policy') }}</strong></p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda dolore rem obcaecati
                    tempore vitae perferendis id aut maiores ea inventore quasi illum dolores aliquid reiciendis
                    voluptatum
                    voluptatibus error asperiores, quidem unde autem. Unde magnam corporis porro odio architecto quo ab
                    dicta dolorum voluptatibus ipsam adipisci in velit repudiandae laudantium excepturi a eos animi
                    officia
                    aperiam reprehenderit, molestiae aspernatur sit doloremque! Excepturi corrupti eum ipsum aliquid
                    nihil
                    dolore, saepe officia aspernatur qui pariatur beatae labore velit praesentium aperiam soluta
                    cupiditate.
                    Vero voluptates, pariatur laudantium ratione alias quam perspiciatis, exercitationem maiores enim
                    nobis
                    quo nostrum debitis labore sapiente sunt tempora corrupti. Nesciunt, quisquam ducimus labore iusto,
                    asperiores, quasi vero excepturi molestias corporis velit veritatis commodi quaerat tempora debitis
                    obcaecati. Velit, et optio.</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis distinctio provident
                    quae
                    dicta nisi facere amet eum molestias et itaque nostrum, nobis at mollitia animi corporis laborum,
                    fuga,
                    tempore totam. Dolores, at cum! Quo eligendi iusto ipsam alias hic nihil fugit? Iure consectetur
                    possimus, magnam recusandae quod reprehenderit eligendi magni, ullam beatae laudantium tempore iste
                    quisquam in optio commodi vel iusto sunt ipsam, laboriosam velit eum! Veniam aperiam incidunt minima
                    facere culpa harum dolore sapiente odio perferendis, assumenda rerum? Enim.</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi porro deserunt
                    laborum,
                    vero, maiores numquam minima eaque earum rerum fugit dicta id aliquam repellat excepturi veniam
                    voluptate enim? Ex ipsam corrupti enim amet sunt deleniti mollitia sit ad praesentium veritatis modi
                    laboriosam blanditiis harum, vero reprehenderit dolorem. Magnam, facere eligendi.</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
                <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque mollitia, autem
                    dolor
                    voluptatem ex fugit, eaque ipsum, odit pariatur temporibus accusantium. Fugit soluta in itaque
                    quisquam
                    reprehenderit dolorem neque pariatur assumenda quod! A reprehenderit, maxime quam itaque numquam
                    illo
                    eveniet delectus dolores accusantium nemo earum ad nam modi, veniam esse expedita laboriosam
                    corporis
                    quidem repellendus quasi velit temporibus. Magni facere velit est ratione iste facilis eos ipsum
                    eveniet, eligendi minus inventore consequatur cumque maxime perspiciatis ducimus ut. Quas, provident
                    consequatur. Molestiae maxime saepe modi labore animi quasi aliquam, repudiandae placeat maiores ex
                    consequatur dolore id temporibus odit sint optio!</p>
            </div>
        </div>
    </main>
</template>

<script>
export default {}
</script>